.rounded.overflow-hidden.shadow-lg.mt-3.p-0.js-plotly-plot {
    height: 100vh;
}
.position-sticky.mt-2.btn.btn-antropomedia-red.btn-lg.d-block.w-100 {
    bottom: 5px;
}
.card>.list-group>.list-group-item>div.float-end>.btn-sm{
    background-color: transparent;
    color: #6C757D;
    width: 14px;
    height: 14px;
    box-sizing: content-box;
    padding: 0.25em;
    border: 0;
    border-radius: 0.5rem;
    svg{
        width: 14px;
        height: 14px;
        vertical-align: top;
    }
    &.btn-close{
        vertical-align: middle;
    }
}
