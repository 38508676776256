.video-container {
    position: relative;
    background-color: #000;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  
  .video-container > video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .video-container > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 1;
  }
  
  .video-container > .container {
    position: relative;
    z-index: 2;
  }
  