@import '../../../assets/styles/custom.scss';

.search-input {
    &__clear-button {
        background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    }
    &.container > .row {
        margin-top: 1rem;
    }
}

.graph-action-btn{
    opacity: 0.5;
    color: gray;
    &:hover {
        opacity: 1;
        color: #000;
    }
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.button-group {
    display: flex;
    align-items: center;
  
    .btn {
      padding: 4px;
      margin-right: 5px;
  
        &.btn-link {
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.75;
            color: #000;
            &:hover {
                opacity: 1;
                color: #000;
            }
      }
    }
}