.video-background {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);

  &-overlay {
    z-index: 1;

    &__over {
      z-index: 2;
    }
  }
}
